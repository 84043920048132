.section {
  @apply p-4 mb-6 md:mb-12;
}

.container {
  @apply mx-auto max-w-7xl px-4 py-12 sm:py-16 sm:px-6 lg:px-8;
}

.wrapper {
  @apply mx-auto max-w-3xl divide-y-2 divide-white;
}

.wrapper h2 {
  @apply mb-8 text-center text-3xl font-bold sm:text-4xl;
}

.wrapper dl {
  @apply mt-6 space-y-6 divide-y divide-white;
}

.wrapper dd {
  @apply mt-2 pr-12;
}

.disclosure {
  @apply pt-6;
}

.disclosure a {
  @apply text-indigo-300 underline;
}

.button {
  @apply flex w-full items-start justify-between text-left;
}

.chevron {
  @apply ml-6 flex h-8 items-center;
}

.chevron__base {
  @apply h-6 w-6 transform;
}

.chevron__open {
  @apply -rotate-180;
}

.chevron__close {
  @apply rotate-0;
}

.transition_base {
  @apply transition ease-out;
}

.transition_enter {
  composes: transition_base;
  @apply duration-100;
}

.transition_leave {
  composes: transition_base;
  @apply duration-75;
}

.transition_enterFrom,
.transition_leaveTo {
  @apply scale-95 transform opacity-0;
}

.transition_enterTo,
.transition_leaveFrom {
  @apply scale-100 transform opacity-100;
}
