/* vietnamese */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8a63bc110e8f45ad-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/00045315ec24c208-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/42ca9a2dc174b9b9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a75fe934ca01b6d6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/70336daae8f9acb3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7ebd901f2f4a0b98-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ed0713aabc469750-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ace9c6b312d37d07-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/95a978e26cc29d74-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d4a6d1072ea531dd-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e1012b8d4e21a3f0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f5e5067cd50e2c82-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Barlow Fallback';src: local("Arial");ascent-override: 103.43%;descent-override: 20.69%;line-gap-override: 0.00%;size-adjust: 96.68%
}.__className_aab8ec {font-family: 'Barlow', 'Barlow Fallback';font-style: normal
}

.styles_DoctorQuote__uN8sI::before {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  z-index: 10;
  display: block;
  height: 2.5rem;
  width: 2.5rem;
}@media (min-width: 768px) {.styles_DoctorQuote__uN8sI::before {
    top: -2.5rem;
    left: -5rem;
  }
}.styles_DoctorQuote__uN8sI::before {
  content: "";
  transform: rotate(180deg);
  background: url("/images/doublequotes.svg") no-repeat;
}

.styles_DoctorQuote__uN8sI::after {
  position: absolute;
  bottom: 3rem;
  right: 1.5rem;
  display: block;
  height: 2.5rem;
  width: 2.5rem;
}

@media (min-width: 768px) {.styles_DoctorQuote__uN8sI::after {
    bottom: -3rem;
    right: -4rem;
  }
}

.styles_DoctorQuote__uN8sI::after {
  content: "";
  background: url("/images/doublequotes.svg") no-repeat;
}

.styles_DoctorQuote_Dark__pibXz::before {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  z-index: 10;
  display: block;
  height: 2.5rem;
  width: 2.5rem;
}

@media (min-width: 768px) {.styles_DoctorQuote_Dark__pibXz::before {
    top: -2.5rem;
    left: -5rem;
  }
}

.styles_DoctorQuote_Dark__pibXz::before {
  content: "";
  transform: rotate(180deg);
  background: url("/images/doublequotes_white.svg") no-repeat;
}

.styles_DoctorQuote_Dark__pibXz::after {
  position: absolute;
  bottom: 3rem;
  right: 1.5rem;
  display: block;
  height: 2.5rem;
  width: 2.5rem;
}

@media (min-width: 768px) {.styles_DoctorQuote_Dark__pibXz::after {
    bottom: -3rem;
    right: -4rem;
  }
}

.styles_DoctorQuote_Dark__pibXz::after {
  content: "";
  background: url("/images/doublequotes_white.svg") no-repeat;
}

.styles_section__aXM2A {
  margin-bottom: 1.5rem;
  padding: 1rem;
}

@media (min-width: 768px) {

  .styles_section__aXM2A {
    margin-bottom: 3rem;
  }
}

.styles_container__5X6zU {
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (min-width: 640px) {

  .styles_container__5X6zU {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {

  .styles_container__5X6zU {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.styles_wrapper__KMJWH {
  margin-left: auto;
  margin-right: auto;
  max-width: 48rem;
}

.styles_wrapper__KMJWH > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-divide-opacity, 1));
}

.styles_wrapper__KMJWH h2 {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
}

@media (min-width: 640px) {

  .styles_wrapper__KMJWH h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.styles_wrapper__KMJWH dl {
  margin-top: 1.5rem;
}

.styles_wrapper__KMJWH dl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-divide-opacity, 1));
}

.styles_wrapper__KMJWH dd {
  margin-top: 0.5rem;
  padding-right: 3rem;
}

.styles_disclosure___R0oo {
  padding-top: 1.5rem;
}

.styles_disclosure___R0oo a {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity, 1));
  text-decoration-line: underline;
}

.styles_button__3bBNn {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
}

.styles_chevron__ycRaQ {
  margin-left: 1.5rem;
  display: flex;
  height: 2rem;
  align-items: center;
}

.styles_chevron__base__EcEOB {
  height: 1.5rem;
  width: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.styles_chevron__open__gSvSf {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.styles_chevron__close__gK_4y {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.styles_transition_base__Zuc3p {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.styles_transition_enter__NIu0c {
  transition-duration: 100ms;
}

.styles_transition_leave__loN0P {
  transition-duration: 75ms;
}

.styles_transition_enterFrom__GgXSV,
.styles_transition_leaveTo__GeRn_ {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
}

.styles_transition_enterTo__si1oQ,
.styles_transition_leaveFrom__kZSQr {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
}

.styles_Highlights___XCLj {
  width: 100%;
}

@media (min-width: 640px) {

  .styles_Highlights___XCLj {
    max-width: 640px;
  }
}

@media (min-width: 768px) {

  .styles_Highlights___XCLj {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {

  .styles_Highlights___XCLj {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {

  .styles_Highlights___XCLj {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {

  .styles_Highlights___XCLj {
    max-width: 1536px;
  }
}

.styles_Highlights___XCLj {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: flex-start;
  justify-content: center;
  vertical-align: middle;
}

@media (min-width: 1024px) {

  .styles_Highlights___XCLj {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .styles_Highlights___XCLj > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
}

.styles_Highlights__Wrapper__dInmD {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.styles_Highlights__Wrapper__Title__LlUsR {
  font-family: Roboto Flex, Roboto, sans-serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 900;
}

@media (min-width: 1536px) {

  .styles_Highlights__Wrapper__Title__LlUsR {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.styles_Highlights__Wrapper__Reviews___4nZI {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.styles_Highlights__Wrapper__Title_mobile__AO0RH {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 1024px) {

  .styles_Highlights__Wrapper__Title_mobile__AO0RH {
    display: none;
  }
}

.styles_Highlights__Wrapper__Title_desktop__AWxKe {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: none;
}

@media (min-width: 1024px) {

  .styles_Highlights__Wrapper__Title_desktop__AWxKe {
    display: block;
  }
}

.styles_Highlights__Wrapper__Quote__DMvpG {
  margin-bottom: 1rem;
  font-weight: 400;
}

.styles_Highlights__Wrapper__Quote__Text__DMqxz {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (min-width: 1536px) {

  .styles_Highlights__Wrapper__Quote__Text__DMqxz {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.styles_Highlights__Wrapper__Quote__Author__yC_au {
  font-style: italic;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.styles_Highlights__Wrapper__dInmD > ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_Highlights__Wrapper__dInmD > ul > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.styles_Highlights__Wrapper__dInmD > ul > li {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.styles_Highlights__Wrapper__dInmD > p {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (min-width: 1024px) {

  .styles_Highlights__Wrapper__dInmD > p {
    margin-bottom: 1rem;
  }
}

.styles_Highlights__Wrapper__ListItem__7pdRt {
  padding-left: 0.25rem;
  font-weight: 900;
}

.styles_Highlights__Wrapper__ListItem__7pdRt:where([dir="rtl"], [dir="rtl"] *) {
  padding-right: 0.25rem;
}

.styles_Highlights__BuyButtonWrapper__nnFQs {
  text-align: center;
}

.styles_Highlights__BuyButton__MYUZQ {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: block;
  width: 100%;
  min-width: 75%;
  cursor: pointer;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.styles_Highlights__BuyButton__MYUZQ:hover {
  text-decoration-line: none;
}

@media (min-width: 640px) {

  .styles_Highlights__BuyButton__MYUZQ {
    display: inline-block;
    width: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (min-width: 1024px) {

  .styles_Highlights__BuyButton__MYUZQ {
    min-width: 100%;
  }
}

@media (min-width: 1280px) {

  .styles_Highlights__BuyButton__MYUZQ {
    min-width: 75%;
  }
}

.styles_Highlights__Shipping__J5rk6 {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.styles_Highlights__Shipping__J5rk6 > img {
  margin-left: auto;
  margin-right: auto;
}

.styles_PromoEvent__HA1cv {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  text-align: center;
}

